<template>
  <div class="notice">
    <div class="notice-item" v-for="n in noticeList" @click="todetails(n)" :key="n.ID">
      <!-- <div class="notice-item-box"> -->
      <div class>
        <div class="notice-name">{{n.Name}}</div>
        <div>{{n.CreateDate}}</div>
      </div>
      <div class="notice-scanDetail">点击查看详情</div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {setStore} from "../../lib/storage";
export default {
  name: "notice",
  props: ["noticeList", "idx"],
  methods: {
    todetails(n) {
      setStore('annidx',this.idx);
      if (this.idx == 1) {
        this.$router.push(`/announceDetail/${n.ID}`);
      } else if (this.idx == 2) {
        this.$router.push(`/announceDetail/${JSON.stringify(n)}`);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.notice {
  // .size(690px, 240px);
  padding: 0 60px;

  &-item {
    display: flex;
    flex: 1;
    height: 110px;
    // padding: 0 60px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(238, 238, 238, 1);
    // margin: 30px auto;
    // border-radius: 20px;
    // background: rgba(255, 255, 255, 1);
    // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    // border-radius: 20px;
    // padding: 30px;
    font-family: "PingFangSC-Regular";
    font-size: 30px;
    color: #333333;
    padding: 25px 0;
    // &-box {
    //   border-bottom: 2px solid rgba(238, 238, 238, 1);
    // }
  }

  &-name {
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 42px;
  }

  &-scanDetail {
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 34px;
  }
}
</style>