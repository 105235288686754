export function getFormat(date) {
    // 时间格式转换   yyyy-mm-dd hh:mm:ss
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    let str = year + '-' + month + '-' + day + " " + hours + ":" + minutes + ":" + seconds;
    return str;
}

export function DelArrNull(data) {
    // 删除数组中空值
    if (data.length) {
        let arr1 = [];
        data.forEach(item => {
            if (item.constructor === Object) {
                arr1.push(item)
            }
        })
        if (arr1.length) {
            return arr1
        } else {
            return ""
        }
    } else {
        return ''
    }
}


 