<template>
  <div class="pad-home">
    <HeaderBar title="消息中心" :showLeft="true"></HeaderBar>

    <div class="navList" ref="navList">
      <!-- the scroll nav list items v-for the navItems -->
      <div class="navItem" v-for="(item,index) in navItems" :key="index" @click="tabNav(index)">
        <div :class="{active:index+1==activeItemId}" class="itemContent">{{item.name}}</div>
      </div>
    </div>
    <!-- <nav-bar :navItems="navItems" :activeItemId="activeId" @tab="tabNav" @popUserInfo="popUserInfo"></nav-bar> -->
    <notice :noticeList="noticeList" :idx="activeItemId"></notice>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import Notice from "@/components/notice/notice";
import { getNotice, SelectEventList } from "@/service/";
import { Toast } from "vant";
import { getFormat } from "../../lib/units";
import { getStore, removeStore } from "../../lib/storage";
export default {
  name: "announce",
  components: {
    HeaderBar,
    Notice
  },
  data() {
    return {
      noticeList: [],
      activeItemId: 1,
      navItems: [
        {
          name: "公告",
          id: 1
        },
        {
          name: "通知",
          id: 2
        }
      ]
    };
  },
  watch: {
    activeItemId(val) {
      if (val == 1) {
        this.getNoticeList();
      } else if (val == 2) {
        this.getSelectEventList();
      }
    }
  },
  methods: {
    tabNav(index) {
      this.activeItemId = index + 1;
    },
    async getSelectEventList() {
      let res = await SelectEventList();
      if (res.code == 1) {
        this.noticeList = res.data.map(x => {
          x.CreateDate = getFormat(x.CreateDate);
          return x;
        });
        // console.log(res)
      } else {
        Toast(res.msg);
      }
    },
    async getNoticeList() {
      let _userInfo = JSON.parse(getStore("userInfo"));
      let res = await getNotice(3, _userInfo.ID);
      if (res.code == 1) {
        this.noticeList = res.data.map(x => {
          x.CreateDate = getFormat(x.CreateDate);
          return x;
        });
        // console.log(res)
      } else {
        Toast(res.msg);
      }
    }
  },
  mounted() {
    this.activeItemId = getStore("annidx") ? getStore("annidx") : 1;
    if (getStore("annidx") && getStore("annidx") == 2) {
      this.getSelectEventList();
    } else {
      this.getNoticeList();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";
.pad-home {
  padding-top: 94px;
}

.navList {
  width: 100%;
  height: 80px;
  align-items: center;
  font-family: "PingFangSC-Regular";
  font-size: 32px;
  font-weight: 400;
  line-height: 80px;
  color: #999999;

  // overflow-x: scroll;
  .navItem {
    float: left;
    width: 100%/2;
    height: 100%;
    .text-center;

    .itemContent {
      display: inline-block;
      height: 80px;
      line-height: 78px;
      box-sizing: border-box;
    }
  }
}

.active {
  font-size: 36px;
  font-weight: 500;
  line-height: 80px;
  color: @brand-green;
  border-bottom: 4px solid @brand-green;
  font-family: "PingFangSC-Medium";
}
</style>
